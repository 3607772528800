import Home from "../../components/landing/Home";
import Headerlanding from "../../components/global/Headerlanding";
import Footer from "../../components/global/Footer";
import Navbar from "../../components/global/Navbar";

const Landing = (props) => {
  return (
    <>
      {/* <Headerlanding /> */}
      <Navbar/>
      <Home />
      <Footer />
    </>
  );
};

export default Landing;
