import React, { Component } from "react";

class Main extends Component {
	render() {
		return (
			<>
			</>
		);
	}
}
export default Main;
