import React, { useState, useLayoutEffect } from "react";
import {
  Button,
  Collapse,
  Container,
  Nav,
  Navbar as RC_Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import Logo from "../../assets/img/biometics imgs/logoH.png";
import { NavLink as Navlink } from "react-router-dom";

const Navbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [scrolled, setScrolled] = useState(false);

  useLayoutEffect(() => {
    const handleScroll = (e) => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="topnav">
      <RC_Navbar
         className="navbar-style"
        // className={scrolled ? "navbar-style" : "not_scrolled"}
        expand="lg"
      >
        {/* <Container> */}
        <NavbarBrand className="navbar-header" href="/">
          <img src={Logo} width="100%" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className={isOpen ? "active" : ""} />
        <Collapse className="ml-auto" isOpen={isOpen} navbar>
          <Nav className="ml-auto font-family-nav" navbar>
            {/* <NavItem>
              <Navlink
                to="/"
                data-target="top"
                target="_self"
                className="navlink"
                activeClass="navlink-active"
                onClick={(e) => window.scrollTo(0, 0)}
              >
                Home
              </Navlink>
            </NavItem> */}
            <NavItem>
              <Navlink
                className="navlink"
                activeClass="navlink-active"
                onClick={(e) => window.scrollTo(0, 0)}
                to="/"
              >
                About
              </Navlink>
            </NavItem>
            <NavItem>
              <Navlink
                className="navlink"
                activeClass="navlink-active"
                onClick={(e) => window.scrollTo(0, 0)}
                to="/"
              >
                Products
              </Navlink>
            </NavItem>
            <NavItem>
              <Navlink
                className="navlink"
                activeClass="navlink-active"
                onClick={(e) => window.scrollTo(0, 0)}
                to="/"
              >
                Pricing
              </Navlink>
            </NavItem>
            <NavItem>
              <Navlink
                className="navlink"
                activeClass="navlink-active"
                onClick={(e) => window.scrollTo(0, 0)}
                to="/"
              >
                Docs
              </Navlink>
            </NavItem>
            <NavItem>
              <Navlink
                className="navlink"
                activeClass="navlink-active"
                onClick={(e) => window.scrollTo(0, 0)}
                to="/"
              >
                Blog
              </Navlink>
            </NavItem>
            <NavItem>
              <Navlink
                className="navlink"
                activeClass="navlink-active"
                onClick={(e) => window.scrollTo(0, 0)}
                to="/"
              >
                Contact
              </Navlink>
            </NavItem>
          </Nav>
        </Collapse>
      </RC_Navbar>
    </div>
  );
};

export default Navbar;
