import React, { useState } from "react";
import Logo from "../../assets/img/biometics imgs/LogoF.png";
import {
  Button,
  Col,
  Collapse,
  Container,
  Nav,
  Navbar as RC_Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <footer className="page-footer font-small">
        {/* <RC_Navbar className="navbar-footer" expand="lg"> */}
        <Row xs="1" md="3" className="w-100 m-0 text-center py-4">
        <Col>
          <NavbarBrand className="navbar-hea" href="/">
            <img src={Logo} width="70%" className="d-flex mr-auto py-0"/>
          </NavbarBrand>
          </Col>
          <Col className="footer-nav">
            {/* <a href="" className="footer-btn">
              <img src={iosbtn} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.chirpchat.chirpchat" className="footer-btn">
              <img src={androidbtn} />
            </a> */}
            <Nav className="m-auto font-family-nav d-flex py-0" navbar>
            <NavItem>
              <Link
                className="navlink text-muted"
                activeClass="navlink-active"
                to="/"
                data-target="top"
              target="_self"
              onClick={(e) => window.scrollTo(0, 0)}

              >
               <small> Privacy Policy</small> -
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="navlink text-muted"
                activeClass="navlink-active"
                to="/"
                onClick={(e) => window.scrollTo(0, 0)}

              >
                <small> Terms of Use</small>
              </Link>
            </NavItem>
          </Nav>
          <p className="text-muted m-0">© Blockchain Biometrics 2022</p>
          </Col>
          <Col className="footer-social px-0">
            {/* <Link href="#" className="social-btn">
              <img src={social1} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social2} />
            </Link>

            <Link href="#" className="social-btn">
              <img src={social3} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social4} />
            </Link>
            <Link href="#" className="social-btn">
              <img src={social5} />
            </Link> */}
          </Col>
          </Row>
        {/* </RC_Navbar> */}
        {/* <div className="footer-navitems">
          <Nav className="m-auto font-family-nav" navbar>
            <NavItem>
              <Link
                className="navlink"
                activeClass="navlink-active"
                to="/privacy"
                data-target="top"
              target="_self"
              onClick={(e) => window.scrollTo(0, 0)}

              >
                Privacy Policy
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="navlink"
                activeClass="navlink-active"
                to="/terms"
                onClick={(e) => window.scrollTo(0, 0)}

              >
                Terms of Use
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="navlink"
                activeClass="navlink-active"
                to="/cookies"
                onClick={(e) => window.scrollTo(0, 0)}

              >
                Cookie Policy
                
              </Link>
            </NavItem>
          </Nav>
        </div> */}
      </footer>
    </>
  );
};

export default Footer;
