import Landing from '../views/auth/Landing';

let routes = [
  {
    path: '/',
    component: Landing,
    layout: 'auth',
  },
  // {
  //   path: '/demo',
  //   component: Demo,
  //   layout: 'auth',
  // },

  // {
  //   path: '/auth/contact',
  //   component: Contact,
  //   layout: 'auth',
  // },
  // {
  //   path: '/about',
  //   component: About_us,
  //   layout: 'auth',
  // },
  // {
  //   path: '/privacy',
  //   component: Privacy_Policy,
  //   layout: 'auth',
  // },
  // {
  //   path: '/terms',
  //   component: TermsofUse,
  //   layout: 'auth',
  // },
  // {
  //   path: '/cookies',
  //   component: CookiePolicy,
  //   layout: 'auth',
  // },
  // {
  //   path: '/auth/about',
  //   component: About,
  //   layout: 'auth',
  // },
];
export default routes;
