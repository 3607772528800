import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Auth from "./layout/Auth";
import Main from "./layout/Main";
import routes from "./routes";

function App() {
  return (
    <div className="font-family">
      {/* <Provider store={store}> */}
      <BrowserRouter>
        <Switch>
          {routes.map((route) => {
            switch (route.layout) {
              case "auth":
                return (
                  <Route exact path={route.path}>
                    <Auth>
                      <route.component />
                    </Auth>
                  </Route>
                );
              case "admin":
                return (
                  <Route exact path={route.path}>
                    <Main>
                      <route.component />
                    </Main>
                  </Route>
                );
            }
          })}
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
      {/* </Provider> */}
    </div>
  );
}
export default App;
