import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import bauth from "../../assets/img/biometics imgs/bauth.jpeg";
import bproof from "../../assets/img/biometics imgs/bulletproof.jpeg";
import AI from "../../assets/img/biometics imgs/Ainteligence.jpeg";
import paud from "../../assets/img/chirp-imgs/Paudimg.png";
import pvdio from "../../assets/img/chirp-imgs/p-vdio-img.png";
import auth from "../../assets/img/biometics imgs/auth.jpeg";
import flogin from "../../assets/img/biometics imgs/facelogin.jpeg";
import global from "../../assets/img/chirp-imgs/global.png";

class Home extends Component {
  render() {
    return (
      <>
        <div className=" mt- home">
          <div className="div-1">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="7" md="7" className="f-coll order-sm-1 order-2">
                <img src={bauth} className="mt-" width="100%" />
              </Col>
              <Col sm="5" md="5" className="f-colr m-auto order-sm-2 order-1">
                <div className="">
                  <h1>
                    Blockchain Based <br />
                    Biometric Authentication.
                  </h1>
                  <h2 className="text-muted font-weight-normal mt-4 mr-lg-5 mr-md-4 pr-lg-5 pr-md-4">
                    Increase trust and security with our developer friendly API
                    docs, and start onboarding your users today with our
                    passwordless facial recognition authentication.
                    <br />
                    <br />
                    We built the secure infrastructure so you can focus on
                    changing the world with your innovation.
                  </h2>
                </div>
              </Col>
            </Row>
          </div>
          <div className="div-2">
            <Row md="2" xs="1" className="f-row2">
              <Col sm="7" md="7" className="f-coll m-auto">
                <div className="">
                  <h1 className="text-site-primary">
                    Blockchain + Biometrics = Bulletproof
                  </h1>
                  <h3 className="text-muted font-weight-normal mt-5">
                    Traditional biometric authentication systems have major
                    security weaknesses. Because they keep all of their users
                    biometric data on a centralized server, they are vulnerable
                    for hackers to attack.
                    <br />
                    <br />
                    Blockchain Biometrics is a government level, triple helix
                    secure blockchain based authentication system that stores
                    all of our users biometric data on our decentralized
                    immutable ledger.
                  </h3>
                </div>
              </Col>
              <Col sm="5" md="5" className="f-colr">
                <img src={bproof} className="mt-" width="100%" />
              </Col>
            </Row>
          </div>
          <div className="div-1">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="6" md="6" className="f-coll order-sm-1 order-2">
                <img src={AI} className="mt-" width="100%" />
              </Col>
              <Col sm="6" md="6" className="f-colr m-auto order-sm-2 order-1">
                <div className="">
                  <h1 className="display-4">Artificial Intelligence</h1>
                  <h4 className="mt-5 mb-0 font-weight-bold">Face Detection</h4>
                  <h4 className="m-0 text-muted">
                    Find and identify human faces in digital images.
                  </h4>
                  <h4 className="mt-4 mb-0 font-weight-bold">Face Signature</h4>
                  <h4 className="m-0 text-muted">
                    Create and store the face signature of each person.
                  </h4>
                  <h4 className="mt-4 mb-0 font-weight-bold">Face Recognition</h4>
                  <h4 className="m-0 text-muted">
                    Verify the person with their registered face signature.
                  </h4>
                  <h4 className="mt-4 mb-0 font-weight-bold">Face Liveness</h4>
                  <h4 className="m-0 text-muted">
                    Detect if the person in the camera is alive and real.
                  </h4>
                  <h4 className="mt-4 mb-0 font-weight-bold">Anti Spoof</h4>
                  <h4 className="m-0 text-muted">
                    Report a person if they try to commit identity fraud.
                  </h4>
                </div>
              </Col>
            </Row>
          </div>
          {/* <div className="bg-circle"> */}
          {/* <div className="div-2" id="p-aud-div">
            <Row md="2" xs="1" className="f-row2" id="p-aud">
              <Col sm="7" md="7" className="f-coll m-auto">
                <div className="mt-3 pl-xl-5 pl-lg-0 ml-xl-4 ml-lg-2">
                  <h1>Push Audio</h1>
                  <h5>
                    Get nostalgic with our walkie-talkie style push audio.
                    Instant, groundbreaking, and another one of our awesome
                    signature features that was designed specifically with you
                    in mind. Fun, engaging, and exciting, push audio provides a
                    new way for you to connect with your friends faster than you
                    can say “over and out”.
                  </h5>
                </div>
              </Col>
              <Col sm="5" md="5" className="f-colr" id="fcolr-pa">
                <img src={paud} className="pa-img" width="70%" />
              </Col>
            </Row>
          </div>
          <div className="div-1" id="p-vdio-div">
            <Row md="2" xs="1" className="f-row1">
              <Col sm="5" md="5" className="f-coll">
                <img src={pvdio} className="mt-" width="100%" />
              </Col>
              <Col sm="7" md="7" className="f-colr m-auto">
                <div className="mt-3 pl-xl-5 pl-lg-0 ml-xl-4 ml-lg-2">
                  <h1>Push Video</h1>
                  <h5>
                    Chirp is the only app to offer walkie-talkie style,
                    push-to-stream video communication. Instant, awesome, and
                    available right at your fingertips, making use of the high
                    quality video functions that we are known for. Utilize this
                    feature and see how easy it is to make instant video
                    communication exciting and personal.
                  </h5>
                </div>
              </Col>
            </Row>
          </div> */}
          {/* </div> */}
          <div className="bg-white div-col">
            {/* <h1>
              The #1 App For <br />
              Instant Communication
            </h1> */}
            <Row md="2" xs="1" className="div-com">
              <Col>
                <Card className="border-0">
                  <h1 className="text-site-primary display-4 mb-2">Authentication</h1>
                  <img src={auth} />
                  <h2 className="text-muted">
                    Ensure the person behind the screen is who they say they
                    are.
                  </h2>
                </Card>
              </Col>
              <Col>
                <Card className="border-0">
                  <h1 className="text-site-primary display-4 mb-2">Face Login</h1>
                  <img src={flogin} />
                  <h2 className="text-muted">
                    Skip the password and use biometric facial recognition to
                    login.
                  </h2>
                </Card>
              </Col>
              {/* <Col>
                <Card className="shadow">
                  <img src={global} />
                  <h2>Global</h2>
                  <h4>Chirp works all around the world.</h4>
                </Card>
              </Col> */}
            </Row>
          </div>
        </div>
      </>
    );
  }
}
export default Home;
